import React, { useState, useEffect } from "react";
import { Image } from "../components/image";
import { ScrollAndShow } from "../components/scroll-and-show";
import { Layout } from "../components/layout";
import { useTranslation } from "react-i18next";
import { SEO } from "../components/seo";
const arrowImgSrc = require("../images/arrow.png");

import { app } from "../lib/firebaseClient";
import { getFirestore, doc, getDoc } from "firebase/firestore/lite";

export const db = getFirestore(app);

type Hour = {
	rubiaBrunch: string;
	rubiaDinner: string;
	tahona: string;
};

const AccessPage = () => {
	const [t, i18n] = useTranslation();

	const [hour, setHour] = useState<Hour>({
		rubiaBrunch: "",
		rubiaDinner: "",
		tahona: "",
	});

	useEffect(() => {
		const fetchHour = async () => {
			const docRef = await getDoc(doc(db, "rubia", "hour"));
			setHour(docRef.data() as Hour);
		};

		fetchHour();
	}, []);

	return (
		<Layout>
			<SEO
				title={`アクセス | RUBIA ルビア 渋谷メキシカン【朝食・ランチ・バー】`}
				description="渋谷はスペイン坂にあるレストラン。東京都渋谷区宇田川町１３−４ 国際ビル C館　03-6416-5253"
				keywords={["メキシコ料理", "渋谷", "スペイン坂", "ルビア", "sarasa"]}
			/>
			<h1 className="h1">アクセス | RUBIA ルビア / DJ sarasa</h1>
			<div className="access">
				<ScrollAndShow>
					<div className="md:flex md:justify-end">
						<div>
							<div className="hero-image-wrapper">
								<img
									src="https://storage.googleapis.com/static.rubia.co.jp/access/access.jpg"
									alt="access"
								/>
								<div className="page-title font-lato">ACCESS</div>
							</div>
						</div>
					</div>
				</ScrollAndShow>

				<div className="google-maps-container">
					<ScrollAndShow>
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.6381196893403!2d139.69658701524614!3d35.66128658019904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d5661979b8f%3A0x1ef6da3a0a5c1460!2z44Or44OT44KiIOa4i-iwt-ODleOCoeOCpOODs-ODoeOCreOCt-OCq-ODsyAmIOODn-OCr-OCveODreOCuOODvOODkOODvA!5e0!3m2!1sja!2sjp!4v1675326143044!5m2!1sja!2sjp"
							style={{ border: 0 }}
							loading="lazy"
						></iframe>
					</ScrollAndShow>
					<ScrollAndShow delay={200}>
						<div
							className={`shop-info ${
								i18n.language === "ja" ? "font-yu" : "font-lato"
							}`}
						>
							<div className="flex logo-wrap">
								<Image filename="logo_footer.png" alt="logo" />
								<Image filename="logo_footer_tahona.png" alt="logo_tahona" />
							</div>
							<a
								href="https://g.page/RUBIA_Tokyo?share"
								target="_blank"
								rel="noopener"
							>
								{i18n.language === "ja" ? (
									<div className="address">
										〒150-0042
										<br />
										東京都渋谷区宇田川町13-4 国際ビルC館{" "}
										<img
											src={arrowImgSrc.default}
											alt="map link"
											height="10px"
											width="10px"
											style={{ display: "inline-block" }}
										/>
									</div>
								) : (
									<div className="address">
										Kokusai Bld.Ckan, 13-4, Udagawacho,
										<br />
										Shibuya Ku, Tokyo, 150-0042{" "}
										<img
											src={arrowImgSrc.default}
											alt="map link"
											height="10px"
											width="10px"
											style={{ display: "inline-block" }}
										/>
									</div>
								)}
							</a>
							<div className="tel">
								{i18n.language === "ja" ? (
									<div className="flex items-start">
										<div>TEL |</div>
										<div className="ml-2">
											<div>
												RUBIA{" "}
												<a className="ml-2" href="tel:05032041744">
													03-6416-5253
												</a>
											</div>
											<div>
												TAHONA{" "}
												<a className="ml-2" href="tel:05032041744">
													03-6416-0353
												</a>
											</div>
										</div>
									</div>
								) : (
									<div className="flex items-start">
										<div>TEL |</div>
										<div className="ml-2">
											<div>
												RUBIA{" "}
												<a className="ml-2" href="tel:05032041744">
													+813-6416-5253
												</a>
											</div>
											<div>
												TAHONA{" "}
												<a className="ml-2" href="tel:05032041744">
													+813-6416-0353
												</a>
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="email">
								<a href="mailto:info@rubia.co.jp">MAIL | info@rubia.co.jp</a>
							</div>
						</div>
					</ScrollAndShow>
				</div>

				{/* <ScrollAndShow>
					<div className="streetview-wrapper">
						<iframe
							src="https://www.google.com/maps/embed?pb=!4v1621093466923!6m8!1m7!1sCAoSLEFGMVFpcE9lWGFmckpuc2dnU0FfSW13X0xNOEc1dnVrMUphaFNGNnplXy16!2m2!1d35.66128583033512!2d139.6987662484042!3f57.43!4f-15.829999999999998!5f0.7820865974627469"
							height="520"
							style={{ border: 0 }}
							loading="lazy"
						></iframe>
					</div>
				</ScrollAndShow> */}

				<ScrollAndShow>
					<div
						className={`floor-info-container ${
							i18n.language === "ja" ? "font-yu" : "font-lato"
						}`}
					>
						<div className="floor">
							{i18n.language === "ja" ? (
								<>
									<div className="floor-text">1F RUBIA Restaurant</div>
									<div className="hours-text">ブランチ：{hour.rubiaBrunch}</div>
									<div className="hours-text">ディナー：{hour.rubiaDinner}</div>
								</>
							) : (
								<>
									<div className="floor-text">1F RUBIA Restaurant</div>
									<div className="hours-text">BRUNCH {hour.rubiaBrunch}</div>
									<div className="hours-text">DINNER {hour.rubiaDinner}</div>
								</>
							)}
						</div>

						<div className="divider" />

						<div className="floor">
							{i18n.language === "ja" ? (
								<>
									<div className="floor-text">
										2F TAHONA Mezcal & Tequila Café Bar
									</div>
									<div className="hours-text">{hour.tahona}</div>
								</>
							) : (
								<>
									<div className="floor-text">
										2F TAHONA Mezcal & Tequila Café Bar
									</div>
									<div className="hours-text">{hour.tahona}</div>
								</>
							)}
						</div>
					</div>
				</ScrollAndShow>
			</div>
		</Layout>
	);
};

export default AccessPage;
